import platform from "platform";

// NOTE we should plan to replace these flags with feature tests where possible
//relying on the user agent is not the recommended way to handle compatibility issues

const mobiletabletRE =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino|android|ipad|playbook|silk/i;
const ioswebviewRE = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i;

const { userAgent } = navigator;

/**
 * Detecte ipad from version 13
 */
export const isIpadIOS =
  userAgent.includes("Macintosh") && navigator.maxTouchPoints > 1;

export const isIos = (platform.os && platform.os.family === "iOS") || isIpadIOS;

// we use this to disable use of @foobar404/wave.js for versions of iOS where it does not work
export const isIosLt14 =
  platform.os &&
  platform.os.family === "iOS" &&
  platform.os.version &&
  parseInt(platform.os.version.split(".")[0], 10) < 14;

export const isIosWebview = ioswebviewRE.test(userAgent);

export const isHighVersionIOS =
  isIos && platform.os && parseInt(platform.os.version || "", 10) >= 8;

export const isBrowserTouch = "ontouchstart" in document.documentElement;

export const isIphoneXorPlus =
  /iphone/i.test(window.navigator.userAgent) && window.screen.height >= 812;

export const isMobileTablet =
  mobiletabletRE.test(userAgent) ||
  platform.product === "iPad" ||
  isIos ||
  isIphoneXorPlus;

export const isChrome =
  /Chrome/.test(navigator.userAgent) &&
  !/Chromium/.test(navigator.userAgent) &&
  /Google Inc/.test(navigator.vendor);

export const isSafari = platform.name === "Safari";

export const isMobileDevice = () =>
  /iPhone|Android/i.test(navigator.userAgent);
