import React, { useState, useEffect } from "react";
import { isMobileTablet } from "../components/utils/detectDevice";

export const Price = (props) => {
  const [animDetected, setAnimDetected] = useState(false);


  const getposition = () => {
    const t = window.scrollY;
    if (t >= (isMobileTablet ? 3300 : 1400)) {
      setAnimDetected(true);
    }
  };
  useEffect(() => {
    if (!animDetected) {
      window.addEventListener("scroll", getposition);
    }
  }, []);
  return (
    <div id="price" className="text-center">
      <div className={`container priceOpacity ${animDetected && "priceAnim"}`}>
        <div className="section-title">
          <h2>Nos formules</h2>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className="col-md-4 col-sm-6"
                  style={{
                    height: "33.33333333%",
                    padding: "15px",
                    maxWidth: "350px",
                    margin: "auto",
                  }}
                >
                  <div className="thumbnail" style={{ height: "100%" }}>
                    {" "}
                    <div
                      className="caption"
                      style={{
                        height: "60%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <h4>{d.name}</h4>
                      <ul>
                        <li>{d.text}</li>
                        <li>{d.text2}</li>
                        <li>{d.text3}</li>
                        {d.text4 && <li>{d.text4}</li>}
                      </ul>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
